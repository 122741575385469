import restapi from 'shared-module/api/restapi'
import handleError from 'shared-module/api/apiError'

const listOwnedSharedCameras = () => {
  return restapi.get('/api/v3/shared-cameras/owned')
    .catch(handleError)
}

const shareCamera = (requestBody) => {
  return restapi.post('/api/v3/shared-cameras/share', requestBody)
    .catch(handleError)
}

const EditCamera = (requestBody) => {
  return restapi.put('/api/v3/shared-cameras/update', requestBody)
    .catch(handleError)
}

const rejectSharedCameraInvitation = (invitationId) => {
  return restapi.get(`/api/v3/shared-cameras/invitations/${invitationId}/reject`)
    .catch(handleError)
}

const acceptSharedCameraInvitation = (invitationId) => {
  return restapi.get(`/api/v3/shared-cameras/invitations/${invitationId}/accept`)
    .catch(handleError)
}

const listAllSharedCameras = () => {
  return restapi.get('/api/v3/shared-cameras/all')
    .catch(handleError)
}
const listSharedCameraInfo = (cameraId) => {
  return restapi.get(`/api/v3/shared-cameras/${cameraId}/`)
    .catch(handleError)
}

const listPendingSharedCameras = () => {
  return restapi.get('/api/v3/shared-cameras/pending')
    .catch(handleError)
}

const listSharedCameraPhotos = (cameraId) => {
  return restapi.get(`/api/v3/shared-cameras/${cameraId}/photos`)
    .catch(handleError)
}

const revokeAccess = (cameraId) => {
  return restapi.delete('/api/v3/shared-cameras/reject-access', { data: { cameraIds: [cameraId] } })
    .catch(handleError)
}

const deleteAccess = ({ cameraIds, guestEmail }) => {
  return restapi.delete('/api/v3/shared-cameras/revoke-access', { data: { cameraIds: cameraIds, guestEmail: guestEmail } })
    .catch(handleError)
}

const cameraSharingApi = {
  listOwnedSharedCameras,
  shareCamera,
  EditCamera,
  rejectSharedCameraInvitation,
  acceptSharedCameraInvitation,
  listAllSharedCameras,
  listPendingSharedCameras,
  listSharedCameraPhotos,
  listSharedCameraInfo,
  revokeAccess,
  deleteAccess,
}

export default cameraSharingApi
