import SvgIcon from '@material-ui/core/SvgIcon'

const CameraSettingsIcon = (props) => (
  <SvgIcon {...props} data-testid="CameraSettingsIcon">
    <path
      d="M11.031 11.645a1.481 1.481 0 00-1.73.273l-.61-.61a4.273 4.273 0 01-5.636-4.722.388.388 0 01.66-.21l.902.897a1.877 1.877 0 002.656 0 1.877 1.877 0 000-2.656l-.898-.902a.388.388 0 01.21-.66 4.276 4.276 0 014.7 5.703l1.317 1.316zm2.399 2.382l1.562-1.558 5.461 5.46c.711.708.711 1.86 0 2.571-.71.71-1.86.71-2.57 0l-4.707-4.707c.472-.477.558-1.2.254-1.766zm-3.078-1.625a.575.575 0 00-.403.168l-5.355 5.36c-.711.707-.711 1.86 0 2.57.707.707 1.86.707 2.57 0l5.356-5.36a.573.573 0 000-.808l-.348-.348 5.805-5.804.273.27c.246.25.656.218.863-.067l1.746-2.367a.566.566 0 00-.058-.743l-1.004-1.007a.575.575 0 00-.746-.055l-2.367 1.742a.576.576 0 00-.067.867l.274.27-5.809 5.808-.324-.328a.584.584 0 00-.406-.168zm0 0"
      fillRule="evenodd"
    />
  </SvgIcon>
)

export default CameraSettingsIcon
