import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

import Paper from '@material-ui/core/Paper'
import { useTheme } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'

import CameraTile from 'camera-module/cameras/ui/cameras-page/CameraTile'
import { useReloadCameras } from 'camera-module/cameras/core/useCameras'
import useCamerasActions from 'camera-module/cameras/core/useCamerasActions'
import messageActions from 'shared-module/message/messageActions'
import cameraActions from 'camera-module/camera/core/cameraActions'
import filtersState from 'photo-module/filters/core/filtersState'
import useQueryParams from 'shared-module/url/useQueryParams'
import useFreeTrialActivationModal from 'camera-module/free-trial-activation-modal/core/useFreeTrialActivationModal'
import { useCameraPlansActions } from 'vosker/src/camera-module/transmission-plan/core/cameraPlans.hook'
import CAppBar from 'storybook-component-module/src/components/containers/CAppBar'
import ActivateCameraTile from 'camera-module/cameras/ui/cameras-page/ActivateCameraTile'
import CameraIcon from 'camera-module/cameras/ui/cameras-page/icons/CameraIcon'
import AddCameraIcon from 'camera-module/cameras/ui/cameras-page/icons/AddCameraIcon'
import CButtonIcon from 'storybook-component-module/src/components/buttons/CButtonIcon'
import LoadingCamera from 'camera-module/cameras/ui/cameras-page/components/LoadingCamera'
import NoCameraTile from 'camera-module/cameras/ui/cameras-page/components/NoCameraTile'
import ActivateCameraButton from 'camera-module/cameras/ui/cameras-page/components/ActivateCameraButton'
import useUser from 'user-module/user/core/useUser'
import ConsentFormDialog from 'spypoint/src/cameras-module/ConsentFormDialog'

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: '150px',
  },
  appbar: {
    background: theme.palette.background.default,
  },
  title: {
    [theme.breakpoints.down('xs')]: {
      fontSize: '1rem !important',
      marginLeft: '-0.5rem',
      paddingTop: '0.2rem',
    },
  },
  icon: {
    width: '1.5rem',
    [theme.breakpoints.down('xs')]: {
      marginTop: '0.1rem',
    },
  },
  paper: {
    display: 'flex',
    flexWrap: 'wrap',
    padding: '0.75rem 2rem 1.5rem',
    background: 'transparent',
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'center',
    },
  },
  addButtonButton: {
    padding: '0.75rem 0',
    color: 'none',
    background: 'none',
    '&:hover': {
      color: 'none',
      background: 'none',
    },
  },
  addButtonIcon: {
    width: 40,
    height: 40,
  },
}))

const CamerasPage = ({ NewCameraForm, addCamera = false }) => {
  const theme = useTheme()
  const classes = useStyles()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [isOpenAddCameraDialog, setIsOpenAddCameraDialog] = useState(addCamera)
  const queryParams = useQueryParams()
  const { cameraHasFreeTrial, openFreeTrialActivationModal } = useFreeTrialActivationModal()
  const cameraPlansActions = useCameraPlansActions()
  const cameras = useReloadCameras()
  const camerasActions = useCamerasActions()
  const history = useHistory()
  const isSmallDisplay = useMediaQuery((theme) => theme.breakpoints.down('xs'))
  const user = useUser()
  const { consent } = user
  const [isOpenConsentDialog, setIsOpenConsentDialog] = useState(false)

  const pageTitle = queryParams.transmission_plan
    ? t('app:purchasing_flow.transmission_plan.select_device')
    : t('menu.cameras')

  useEffect(() => {
    if (isOpenAddCameraDialog && !addCamera) {
      history.push('/add-camera')
    }
    if (!isOpenAddCameraDialog && addCamera) {
      history.push('/')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpenAddCameraDialog, addCamera])

  useEffect(() => {
    dispatch(cameraActions.clear())
    dispatch(cameraPlansActions.reset())
    dispatch(filtersState.clear())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!consent) setIsOpenConsentDialog(true)
  }, [consent])

  const onAddCameraSuccess = (message, camera) => {
    dispatch(messageActions.showMessage(message))
    camerasActions.get()
    if (cameraHasFreeTrial(camera)) {
      openFreeTrialActivationModal(camera)
    }
  }

  const toggleAddCamera = () => setIsOpenAddCameraDialog(!isOpenAddCameraDialog)

  const renderCameras = () => {
    if (!cameras) {
      return <LoadingCamera />
    }

    if (cameras.length === 0) {
      return <NoCameraTile />
    }

    return (
      <Paper className={classes.paper} elevation={0}>
        { cameras.map((camera) => (
          <CameraTile isNewUi key={camera.id} camera={camera} />
        )) }
        { isSmallDisplay
          ? (
            <ActivateCameraButton toggleAddCamera={toggleAddCamera} displayFullWidth />
            )
          : (
            <ActivateCameraTile toggleAddCamera={toggleAddCamera} displayText={false} isNewUi />
            ) }
      </Paper>
    )
  }

  return (
    <div className={classes.root}>
      <CAppBar
        variant="h1"
        Icon={CameraIcon}
        title={pageTitle}
        className={classes.appbar}
        titleClassName={classes.title}
        iconClassName={classes.icon}
        showDivider
      >
        { isSmallDisplay
          ? (
            <CButtonIcon onClick={toggleAddCamera} className={classes.addButtonButton}>
              <AddCameraIcon className={classes.addButtonIcon} color={theme.palette.secondary.main} />
            </CButtonIcon>
            )
          : (
            <ActivateCameraButton toggleAddCamera={toggleAddCamera} displayFullWidth={!isSmallDisplay} />
            ) }
      </CAppBar>

      { renderCameras() }
      { isOpenConsentDialog && <ConsentFormDialog isNewUi onClose={() => setIsOpenConsentDialog(false)} /> }
      { addCamera && <NewCameraForm onClose={toggleAddCamera} onAddCameraSuccess={onAddCameraSuccess} /> }
    </div>
  )
}

export default CamerasPage
