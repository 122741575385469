import { makeStyles } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import NewSignalZero from 'assets/icons/status/vkx/NewSignalZero'
import NewSignalOne from 'assets/icons/status/vkx/NewSignalOne'
import NewSignalTwo from 'assets/icons/status/vkx/NewSignalTwo'
import NewSignalThree from 'assets/icons/status/vkx/NewSignalThree'
import NewSignalFour from 'assets/icons/status/vkx/NewSignalFour'
import NewSignalFive from 'assets/icons/status/vkx/NewSignalFive'
import Box from '@mui/material/Box'

const useStyles = makeStyles(theme => ({
  root: ({ isNewUi }) => ({
    margin: '20px',
    width: isNewUi ? '10.25rem' : '200px',
    height: isNewUi && '7.25rem',
    textAlign: 'center',
  }),
  icon: {
    width: '50px',
  },
  value: ({ isNewUi }) => ({
    fontSize: isNewUi ? '0.813rem' : '11pt',
    fontFamily: isNewUi && theme.typography.fontFamilyBold,
    fontWeight: !isNewUi && 'bold',
    marginTop: isNewUi ? '10px' : '7px',
  }),
  valueLowSignal: ({ isNewUi }) => ({
    marginTop: isNewUi ? '10px' : '7px',
    color: isNewUi ? theme.palette.status?.error : theme.palette.error.main,
  }),
  label: ({ isNewUi }) => ({
    marginTop: isNewUi ? '0px' : '4px',
    fontSize: isNewUi ? theme.typography.caption2?.fontSize : '10px',
    color: theme.palette.text.primary,
  }),
}))

const CellularSignalTile = ({ value, isNewUi }) => {
  const { t } = useTranslation()
  const classes = useStyles({ isNewUi })

  const Icon = (() => {
    switch (value.bar) {
      case 0: return NewSignalZero
      case 1: return NewSignalOne
      case 2: return NewSignalTwo
      case 3: return NewSignalThree
      case 4: return NewSignalFour
      case 5: return NewSignalFive
      default: return NewSignalZero
    }
  })()

  const signalText = (() => {
    switch (value.bar) {
      case 0: return t('camera.no_signal')
      case 1: return '1/5'
      case 2: return '2/5'
      case 3: return '3/5'
      case 4: return '4/5'
      case 5: return '5/5'
      default: return t('camera.no_signal')
    }
  })()

  const isValueLowSignal = value.lowSignal || value.bar === 0 || value.bar === 1

  return (
    <div className={classes.root} data-testid="tile">
      { isNewUi
        ? (
          <Box pl={0.325} display="flex" justifyContent="center">
            <svg width="32" height="32" viewBox="0 0 32 32"><Icon /></svg>
          </Box>
          )
        : (
          <div>
            <Icon className={classes.icon} />
          </div>
          ) }
      <div className={[classes.value, isValueLowSignal ? classes.valueLowSignal : ''].join(' ')}>
        { signalText }
      </div>
      <div className={classes.label}>
        { t('camera.signal') }
      </div>
    </div>
  )
}

export default CellularSignalTile
