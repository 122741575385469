import SvgIcon from '@material-ui/core/SvgIcon'
import { useTheme } from '@material-ui/core'

const SolarBatteryEmpty = ({ props }) => {
  const theme = useTheme()

  return (
    <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
      <g clip-path="url(#clip0_1064_3208)">
        <path d="M10.5 2.85352C10.8431 2.85352 11.1213 2.59801 11.1213 2.28282V0.570704C11.1213 0.255513 10.8431 0 10.5 0C10.1568 0 9.87865 0.255513 9.87865 0.570704V2.28282C9.87865 2.59801 10.1568 2.85352 10.5 2.85352Z" fill={theme.palette.status?.error} />
        <path d="M17.2262 6.42042C17.3977 6.69339 17.7777 6.78691 18.0749 6.62932L19.6892 5.77326C19.9864 5.61566 20.0882 5.26663 19.9166 4.99366C19.745 4.7207 19.365 4.62717 19.0679 4.78477L17.4536 5.64083C17.1564 5.79842 17.0546 6.14746 17.2262 6.42042Z" fill={theme.palette.status?.error} />
        <path d="M14.3833 3.80927C14.6805 3.96687 15.0605 3.87334 15.2321 3.60038L16.1641 2.11764C16.3357 1.84468 16.2339 1.49564 15.9367 1.33804C15.6395 1.18045 15.2595 1.27397 15.0879 1.54693L14.1559 3.02967C13.9843 3.30264 14.0862 3.65167 14.3833 3.80927Z" fill={theme.palette.status?.error} />
        <path fill-rule="evenodd" clip-rule="evenodd" d="M1.09226 4.97993L2.53458 6.42225L2.52932 6.41946L4.60986 8.5H4.61233L6.61233 10.5H6.60986L15.6099 19.5H15.6123L17.6123 21.5H17.6099L19.1885 23.0786L18.2692 23.9979L15.7713 21.5H2C1.46957 21.5 0.960859 21.2893 0.585786 20.9142C0.210714 20.5391 0 20.0304 0 19.5V10.5C0 9.96957 0.210714 9.46086 0.585786 9.08579C0.960859 8.71071 1.46957 8.5 2 8.5H2.77133L0.161618 5.89029L1.08088 4.97102L1.09128 4.98143L1.09226 4.97993ZM4.77133 10.5H2.5C2.36739 10.5 2.24021 10.5527 2.14645 10.6464C2.05268 10.7402 2 10.8674 2 11V19C2 19.1326 2.05268 19.2598 2.14645 19.3536C2.24021 19.4473 2.36739 19.5 2.5 19.5H13.7713L4.77133 10.5Z" fill={theme.palette.status?.error} />
        <path d="M18.5 19.5H17.4509L19.4087 21.4578C19.7867 21.3789 20.137 21.1914 20.4142 20.9142C20.7893 20.5391 21 20.0304 21 19.5V18.5H22C22.5304 18.5 23.0391 18.2893 23.4142 17.9142C23.7893 17.5391 24 17.0304 24 16.5V13.5C24 12.9696 23.7893 12.4609 23.4142 12.0858C23.0391 11.7107 22.5304 11.5 22 11.5H21V10.5C21 9.96957 20.7893 9.46086 20.4142 9.08579C20.0391 8.71071 19.5304 8.5 19 8.5H15.5054C15.2561 6.23806 13.1103 4.47062 10.5002 4.47062C8.20196 4.47062 6.26379 5.84081 5.66316 7.7123L8.45086 10.5H18.5C18.6326 10.5 18.7598 10.5527 18.8536 10.6464C18.9473 10.7402 19 10.8674 19 11V12.5C19 12.7652 19.1054 13.0196 19.2929 13.2071C19.4804 13.3946 19.7348 13.5 20 13.5H21.75C21.8163 13.5 21.8799 13.5263 21.9268 13.5732C21.9737 13.6201 22 13.6837 22 13.75V16.25C22 16.3163 21.9737 16.3799 21.9268 16.4268C21.8799 16.4737 21.8163 16.5 21.75 16.5H20C19.7348 16.5 19.4804 16.6054 19.2929 16.7929C19.1054 16.9804 19 17.2348 19 17.5V19C18.9974 19.1318 18.9439 19.2575 18.8507 19.3507C18.7575 19.4439 18.6318 19.4974 18.5 19.5Z" fill={theme.palette.status?.error} />
        <path d="M6.61663 3.80927C6.91381 3.65167 7.01563 3.30264 6.84405 3.02967L5.91205 1.54693C5.74047 1.27397 5.36047 1.18045 5.06328 1.33804C4.7661 1.49564 4.66428 1.84468 4.83586 2.11764L5.76786 3.60038C5.93944 3.87334 6.31945 3.96687 6.61663 3.80927Z" fill={theme.palette.status?.error} />
      </g>
    </SvgIcon>
  )
}

export default SolarBatteryEmpty
