import * as Yup from 'yup'
import { getInitialCell } from 'spypoint/src/user-module/account/profile/info/ProfileCell'
import { parsePhoneNumberFromString } from 'libphonenumber-js/max'

const rules = {}

const userInfoInitialValues = (user) => ({
  firstName: user.firstName || '',
  lastName: user.lastName || '',
  company: user.company || '',
  cell: getInitialCell(user),
  address: user.address || '',
  apartment: user.apartment || '',
  city: user.city || '',
  postalCode: user.postalCode || '',
  country: user.country || '',
  territory: user.territory || '',
})

const profileFromUser = (user, language) => {
  let cell = ''
  if (user.cell) {
    cell = parsePhoneNumberFromString(user.cell)
  }

  return {
    firstName: user.firstName || '',
    lastName: user.lastName || '',
    email: user.email || '',
    city: user.city || '',
    company: user.company || '',
    cell: cell || '',
    address: user.address || '',
    apartment: user.apartment || '',
    country: user.country || '',
    territory: user.territory || '',
    postalCode: user.postalCode || '',
    notificationEmail: user.notificationEmail || false,
    marketingEmail: user.marketingEmail || true,
    language,
  }
}

const userPreferencesInitialValues = (user) => ({
  language: user.language || 'en',
  notificationEmail: user.notificationEmail || false,
  marketingEmail: user.marketingEmail,
})

const validationSchema = () => {
  rules.firstName = Yup.string().required('input.first_name_required').max(30)
  rules.lastName = Yup.string().required('input.last_name_required').max(40)
  rules.address = Yup.string().required('input.address_required')
  rules.apartment = Yup.string()
  rules.postalCode = Yup.string()
    .required('input.postal_code_required')
    .when('country', {
      is: 'US',
      then: Yup.string().matches(/^\d{5}$/, 'input.postal_code_invalid'),
      otherwise: Yup.string().when('country', {
        is: 'CA',
        then: Yup.string().matches(/^([A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d)$/, 'input.postal_code_invalid'),
        otherwise: Yup.string().matches(/^[A-Za-z\d\s-]*$/, 'input.postal_code_invalid'),
      }),
    })
    .matches(/^[A-Za-z\d\s-]*$/, 'input.postal_code_invalid')
  rules.city = Yup.string().required('input.city_required')
  rules.country = Yup.string().required('input.country_required')
  rules.territory = Yup.string().required('input.territory_required')

  return Yup.object().shape(rules)
}

const profileHelpers = { userInfoInitialValues, userPreferencesInitialValues, validationSchema, profileFromUser }
export default profileHelpers
export { profileFromUser }
