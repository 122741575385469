import jwtDecode from 'jwt-decode'

const tokenId = 'token'

const get = () => localStorage.getItem(tokenId)
const set = (token) => localStorage.setItem(tokenId, token)
const remove = () => localStorage.removeItem(tokenId)
const decodeUserId = () => jwtDecode(get()).user._id

export default { get, set, remove, decodeUserId }
