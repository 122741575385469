import SvgIcon from '@material-ui/core/SvgIcon'

const CameraSharing = (props) => (
  <SvgIcon {...props} width="20" height="20" viewBox="0 0 20 20" fill="none" data-testid="camerasharing">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.0018 2.09277C11.0851 2.09277 10.2548 2.49122 9.65179 3.13614C11.415 3.85546 12.6685 5.69173 12.6685 7.84346C12.6685 8.32918 12.6047 8.79885 12.4854 9.24321C14.0985 8.9908 15.3375 7.49465 15.3375 5.68672C15.3375 3.70184 13.8441 2.09277 12.0018 2.09277ZM18.007 15.7509H15.338V13.5265L14.637 12.879L14.6345 12.8767L14.6313 12.8738L14.6279 12.8708L14.6216 12.865L14.6099 12.8544C14.5983 12.844 14.5847 12.8322 14.5694 12.8188C14.5385 12.7921 14.5 12.7597 14.454 12.7224C14.3618 12.648 14.2389 12.5539 14.0852 12.4475C13.7778 12.2346 13.3462 11.9711 12.7904 11.7145C12.4064 11.5372 11.9649 11.364 11.4668 11.2131C11.7686 10.8527 12.0227 10.4449 12.2184 10.0015C13.989 10.0333 15.3402 10.4508 16.2681 10.8793C16.75 11.1018 17.1162 11.3265 17.367 11.5002C17.4924 11.5871 17.5889 11.6612 17.6567 11.716C17.6906 11.7434 17.7174 11.7659 17.7368 11.7828C17.7467 11.7913 17.7546 11.7984 17.7608 11.8039L17.7687 11.8111L17.7717 11.8137L17.7729 11.8149L17.7735 11.8154C17.7737 11.8156 17.774 11.8158 17.3398 12.3617L17.774 11.8158L18.007 12.0311V15.7509ZM4.66271 7.84346C4.66271 5.85857 6.15614 4.24951 7.99837 4.24951C9.84062 4.24951 11.334 5.85857 11.334 7.84346C11.334 9.82833 9.84062 11.4374 7.99837 11.4374C6.15614 11.4374 4.66271 9.82833 4.66271 7.84346ZM13.7705 13.9726L14.0035 14.1878V17.9076H1.99316V14.1878L2.22617 13.9726L2.22723 13.9716L2.22846 13.9705L2.23142 13.9678L2.23936 13.9606C2.24555 13.9551 2.25353 13.948 2.2633 13.9396C2.28285 13.9227 2.30957 13.9001 2.34347 13.8727C2.41126 13.8179 2.50783 13.7438 2.63321 13.657C2.88395 13.4833 3.25022 13.2585 3.73205 13.036C4.6974 12.5903 6.12099 12.1563 7.99836 12.1563C9.87571 12.1563 11.2993 12.5903 12.2646 13.036C12.7465 13.2585 13.1128 13.4833 13.3635 13.657C13.4889 13.7438 13.5855 13.8179 13.6532 13.8727C13.6871 13.9001 13.7139 13.9227 13.7334 13.9396C13.7402 13.9455 13.7461 13.9507 13.7512 13.9552C13.7534 13.9571 13.7555 13.959 13.7573 13.9606L13.7653 13.9678L13.7683 13.9705L13.7695 13.9716L13.77 13.9721L13.7705 13.9726Z"
      fill="#F5F4F4"
    />

  </SvgIcon>
)

export default CameraSharing
