import SvgIcon from '@material-ui/core/SvgIcon'

const PhotoGalleryIcon = (props) => (
  <SvgIcon {...props} data-testid="PhotoGalleryIcon">
    <path
      d="M23.547 4.715l-1.645-.184-.144-1.25-5.977.574L2.723 2.41l-.285 2.367-.22-.007-.011.382.188-.015v.004l-.895.086.434 3.757L.77 18.691l.937.106-.09 2.465 21.13.707.108-2.965.704-.067-.532-4.628.278-7.575zM4.082 4.063l4.434.492-4.871.465.003-.012 1.422-.14-1.398-.048.004-.043.316.035zm-.625 2.472L3.332 7.56l1.281 11.683 17.133-1.726L20.47 5.875l-2.047-.227-.387.743-1.172 1.09-1.781 1.144-1.805.816-1.71.536-.169.328 2.102-.43 2.473-.07-1.883 1.664.562.043 2.192 2.36-.645-.235 2.63 3.191-1.4-.633 1.676 1.57-9.562.965-.113-1.43.218-.96 1.825-.43 1.46-.508.622-1.246-.426-1.066.187 1.02-.609.992-1.363.398-1.903.34-1.613-.055-.723.39-.668.044-.964-.637-.164-.926-.391-.437.82-.844 2.637-1.727.371-.671 1.191-.422v-.207l-1.699-.145-1.21-.515-.7-.739-.394-1.195.628 1.062.692.536.684.246.375.043-.133-.598-.301-.563-.414-.644-.61-.3.872.19.605.57.453.673.453.508.512.156.77-.055 2.113-1.215.918-1.226.11-1.625zm11.324-1.137l.489 1.262.277.133 1.437-.938.23-.34-1.718-.19zM2.492 14.313l.028-.227.78 6.793-.085.012zm18.746 6.101l-9.703-.324 9.75-.934zm0 0"
      fillRule="evenodd"
    />
  </SvgIcon>
)

export default PhotoGalleryIcon
