const localConfig = {
  apm: {
    switch: 'off',
  },
  spydollars: {
    switch: 'off',
  },
  gtm: {
    switch: 'off',
    id: 'GTM-MMVRKB8',
    auth: 'fwpZyqUqRolUv8i_oFyucg',
    prev: 'env-24',
  },
  cart: {
    switch: 'on',
  },
  notificationCenter: {
    switch: 'on',
  },
  detectionZone: {
    switch: 'on',
  },
  detectionZoneForWebapp: {
    switch: 'off',
  },
  deersense: {
    switch: 'off',
  },
  accountDashboard: {
    switch: 'on',
    plansAndSubscriptions: {
      switch: 'on',
    },
    payment: {
      switch: 'on',
    },
    management: {
      switch: 'on',
    },
  },
  flex: {
    switch: 'on',
  },
  restrictInstantMode: {
    switch: 'on',
  },
  newLanguagesSwitch: {
    german: {
      switch: 'on',
    },
    spanish: {
      switch: 'on',
    },
  },
  cameraActivation: {
    switch: 'on',
  },
  consent: {
    switch: 'on',
  },
  consentExistingUsers: {
    switch: 'on',
  },
  batteryIcons: {
    switch: 'on',
  },
  cameraSettings: {
    switch: 'off',
  },
  cameraStatus: {
    switch: 'on',
  },
  updateFirmware: {
    switch: 'on',
  },
  formatStorage: {
    switch: 'on',
  },
  videoPreviews: {
    switch: 'off',
  },
  newPaymentMethods: {
    switch: 'on',
  },
  sharedCamera: {
    switch: 'on',
  },
  eCommerce: {
    switch: 'off',
  },
  galleryOnDemand: {
    switch: 'on',
  },
}

export default window.config === undefined ? localConfig : window.config
