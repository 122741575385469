import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'

import Form from 'form-module/ui'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'

import useUser from 'user-module/user/core/useUser'
import dialogActions from 'shared-module/dialog/dialogActions'
import useUserActions from 'user-module/user/core/useUserActions'
import BillingAddressForm from 'checkout-module/billing/BillingAddressForm'
import BillingAddressDisplay from 'checkout-module/billing/BillingAddressDisplay'
import billingAddressHelpers from 'checkout-module/billing/BillingAddressHelpers'
import { validateCell } from 'spypoint/src/user-module/account/profile/info/ProfileCell'

const VoskerBillingAddress = ({ setIsPurchaseDisabled, createOrderPreview }) => {
  const user = useUser()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const userActions = useUserActions()

  const [isModifying, setIsModifying] = useState()
  const [validationOnChange, setValidationOnChange] = useState(user.isAddressComplete)

  const handleModificationChange = () => {
    setIsModifying(!isModifying)
    setIsPurchaseDisabled(false)
  }

  const onSubmit = data => {
    const updatedUser = { ...data, id: user.id }

    userActions.update(updatedUser).then(() => {
      setIsModifying(false)
      setIsPurchaseDisabled(false)
      createOrderPreview()
    }).catch(() =>
      dispatch(dialogActions.showError(t('app:order_page.error.billing-address.message'))))
  }

  return (
    <>
      <Box mb={3}>
        <Typography variant="h2">
          { t('menu.billing') }
        </Typography>
      </Box>
      <Grid container item alignItems="flex-end" justifyContent="flex-end" spacing={2}>
        { user.isAddressComplete && !isModifying
          ? <BillingAddressDisplay isNewUi modifyBillingAddress={handleModificationChange} />
          : (
            <Form
              id="billing-address-form"
              onSubmit={onSubmit}
              data={billingAddressHelpers.initialValues(user)}
              validate={validateCell}
              validateOnBlur={user.isAddressComplete}
              validateOnChange={validationOnChange}
              validationSchema={billingAddressHelpers.validationSchema()}
            >
              <BillingAddressForm
                user={user}
                isNewUi
                onSubmit={onSubmit}
                onCancel={handleModificationChange}
                setIsPurchaseDisabled={setIsPurchaseDisabled}
                setValidationOnChange={setValidationOnChange}
              />
            </Form>
            ) }
      </Grid>
    </>
  )
}

export default VoskerBillingAddress
