import Box from '@material-ui/core/Box'
import List from '@material-ui/core/List'
import TransmissionPlanIcon from '@material-ui/icons/ShoppingCart'
import LinkMenuItem from 'shared-module/navigation/menu/items/LinkMenuItem'
import PhotoGalleryIcon from 'assets/icons/navigation/PhotoGalleryIcon'
import CameraIcon from 'assets/icons/navigation/CameraIcon'
import CameraSettingsIcon from 'assets/icons/navigation/CameraSettingsIcon'
import useSelectedCamera from 'camera-module/camera/core/useSelectedCamera'
import CameraInfo from 'shared-module/navigation/menu/camera-items/CameraInfo'
import CameraStatusMenuItem from 'spypoint/src/shared-module/navigation/menu/items/CameraStatusMenuItem'

const CameraMenuItems = ({ onClose }) => {
  const camera = useSelectedCamera()
  const shared = JSON.parse(localStorage.getItem('isShared'))

  const offline = camera?.status?.permanentlyShutdown || false
  const hasAvailableUpdate = camera?.status?.update && Object.keys(camera.status.update).length !== 0 && !camera.status.update.disabled
  const hasLowBattery = camera?.status?.batteries?.find(battery => battery < 20)
  const alert = hasAvailableUpdate || hasLowBattery

  return camera
    ? (
      <>
        <Box mb={1.5} pb={1.5} bgcolor="background.paper">
          <List>
            <CameraInfo />
          </List>
        </Box>
        <List>
          { camera.isCellular && !offline && <LinkMenuItem Icon={TransmissionPlanIcon} text="menu.manage_my_plan" path={`/camera/${camera.id}/plan`} onClick={onClose} /> }
          <LinkMenuItem Icon={PhotoGalleryIcon} text="menu.photo_gallery" path={`/camera/${camera.id}`} onClick={onClose} />
          { (camera.isCellular || shared) && <CameraStatusMenuItem Icon={CameraIcon} text="menu.camera_status" path={`/camera/${camera.id}/status`} onClick={onClose} alert={alert} /> }
          { camera.isCellular && <LinkMenuItem Icon={CameraSettingsIcon} text="menu.camera_settings" path={`/camera/${camera.id}/settings`} onClick={onClose} /> }
        </List>
      </>
      )
    : null
}

export default CameraMenuItems
