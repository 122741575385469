import apm from 'shared-module/monitoring/apm'

import userApi from 'user-module/user/api/userApi'
import { SET_USER } from 'user-module/user/core/userActionTypes'

const set = user => ({ type: SET_USER, user })

const getAndSet = userId => dispatch => userApi
  .getById(userId)
  .then(user => {
    apm.setUserContext({ id: user.id, email: user.email })
    return dispatch(set(user))
  })

const update = (user) => dispatch => {
  return userApi.update(user)
    .then(() => dispatch(getAndSet(user.id)))
}

const userActions = {
  getAndSet: getAndSet,
  update: update,
  updateExtended: () => ({ type: 'NO_OP' }),
  getScoutingPackage: () => ({ type: 'NO_OP' }),
  updateFavoritesCount: () => ({ type: 'NO_OP' }),
}
export default userActions
