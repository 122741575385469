import SvgIcon from '@material-ui/core/SvgIcon'
import { useTheme } from '@material-ui/core'

const YellowZone = ({ props }) => {
  const theme = useTheme()
  return (
    <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.75" y="0.75" width="16.67" height="16.67" rx="3.25" fill="#F9C20A" />
    </SvgIcon>

  )
}

export default YellowZone
