import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import Divider from '@material-ui/core/Divider'
import { makeStyles } from '@material-ui/core/styles'
import { useDispatch } from 'react-redux'

import apm from 'shared-module/monitoring/apm'
import CameraIcon from 'assets/icons/navigation/CameraIcon'
import CameraIconVosker from 'assets/icons/navigation/CameraIconVosker'
import CAppBar from 'storybook-component-module/src/components/containers/CAppBar'

import useCamera from 'camera-module/camera/core/useCamera'
import cameraCommandsApi from '../../api/cameraCommandsApi'
import CameraStatusTiles from 'camera-module/camera/ui/camera-status-page/CameraStatusTiles'
import VoskerSecondaryButton from 'vosker/src/components/buttons/VoskerSecondaryButton'
import appConfig from 'shared-module/config/appConfig'
import switches from 'shared-module/config/switches'
import { UPDATE_STATUS } from '../../core/cameraSettingsButtonInputTypes'
import useCameraCommand from './UseCameraCommand'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material'

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: '150px',
  },
  appbar: {
    background: theme.palette.background.default,
  },
  paper: {
    display: 'flex',
    flexWrap: 'wrap',
    padding: '20px',
    margin: '20px',
    boxShadow: 'none',
  },
  dividerContainer: {
    marginTop: '-20px',
    marginBottom: '30px',
    [theme.breakpoints.down('xs')]: {
      marginTop: '-38px',
      marginBottom: '30px',
    },
  },
  container: {
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'center',
    },
  },
  FormatButtons: {
    height: '32px',
  },
}))

const CameraStatusPage = ({ match, isNewUi }) => {
  const theme = useTheme()
  const classes = useStyles()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const camera = useCamera(match.params.id)

  const VKXModel = camera?.status?.powerSources?.length > 0
  const InstantUpdate = camera?.config?.operationMode === 'instant'
  const StatusInstantEnabled = appConfig.cameraStatus?.switch === switches.on
  const updateStatus = StatusInstantEnabled && VKXModel && InstantUpdate
  const { sendCommands, requested, commandName } = useCameraCommand(camera, cameraCommandsApi, dispatch, isNewUi)
  const isMobileResolution = useMediaQuery(theme.breakpoints.only('xs'))

  useEffect(() => {
    if (camera) {
      apm.log('camera-status', [{ cameraModel: camera.status.model }])
    }
  }, [camera])

  const renderCameraStatus = () => (
    <Paper className={classes.paper} elevation={1}>
      <Grid container className={classes.container}>
        <CameraStatusTiles
          cameraStatus={camera.status}
          cameraConfig={camera.config}
          firmwareUpdate={camera.firmwareUpgradeAvailable}
          requested={requested}
          sendCommands={sendCommands}
          commandName={commandName}
          isNewUi
        />
      </Grid>
    </Paper>
  )

  const renderSelectCameraMessage = () => (
    <Paper className={classes.paper} elevation={1}>
      <div>{ t('camera_list.choose') }</div>
    </Paper>
  )

  return (
    <div className={classes.root}>
      <Divider className={classes.dividerContainer} />
      <CAppBar
        variant={isMobileResolution ? 'h3' : 'h1'}
        Icon={isNewUi ? CameraIconVosker : CameraIcon}
        title={t('menu.camera_status')}
        cameraStatus={camera?.status}
        className={classes.appbar}
      >

        { updateStatus && isNewUi && (
          <Grid>
            <Grid container xs={12} justifyContent="flex-end" item>
              { requested
                ? (
                  <VoskerSecondaryButton
                    color="primary"
                    variant="outlined"
                    disabled={requested}
                    className={classes.FormatButtons}
                  >
                    { commandName === 'updateStatus' ? t('camera.settings.buttons.updating') : t('camera.settings.buttons.update') }
                  </VoskerSecondaryButton>
                  )
                : (
                  <VoskerSecondaryButton
                    color="primary"
                    variant="outlined"
                    onClick={() => {
                      sendCommands(UPDATE_STATUS, false)
                    }}
                    className={classes.FormatButtons}
                  >
                    { t('camera.settings.buttons.update') }
                  </VoskerSecondaryButton>
                  ) }
            </Grid>
          </Grid>
        ) }
      </CAppBar>
      { camera ? renderCameraStatus() : renderSelectCameraMessage() }
    </div>
  )
}

export default CameraStatusPage
