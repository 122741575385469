import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import ProfileSelect from 'spypoint/src/user-module/account/profile/preferences/ProfileSelect'
import { MenuItem } from '@material-ui/core'
import { VoskerInputStyles } from 'vosker/src/components/buttons/VoskerInputField'

const ChangeLanguageList = ({ form, formik, items, name }) => {
  const { t, i18n } = useTranslation()
  const localStorageLangage = localStorage.getItem('i18nextLng')

  useEffect(() => {
    if (localStorageLangage && localStorageLangage !== formik.values.language) {
      formik.setFieldValue(name, localStorageLangage)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    i18n.changeLanguage(formik.values.language)
  }, [formik.values.language, i18n])

  return (
    <div>
      <ProfileSelect
        form={form}
        formik={formik}
        name={name}
        label={t('input.change_user_language')}
        items={items}
        className={VoskerInputStyles().input}
        {...formik.getFieldProps(name)}
      >
        {
            items.map(item => (
              <MenuItem
                key={item.key}
                value={item.value}
              > { item.name }
              </MenuItem>
            ))
          }
      </ProfileSelect>
    </div>
  )
}

export default ChangeLanguageList
