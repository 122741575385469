import VoskerPhotoCountElement from './VoskerPhotoCountElement'
import FullHdVideoCountElement from './FullHdVideoCountElement'
import StreamingMinutesElement from './StreamingMinutesElement'
import VoskerHdPhotoCountElement from './VoskerHdPhotoCountElement'
import VoskerTransmissionPlanNameElement from './VoskerTransmissionPlanNameElement'
import CameraNameElement from 'photo-module/photos/ui/photos-gallery/information-bar/elements/CameraNameElement'

const VoskerTopBarItems = ({ hideCameraName = false }) => (
  <>
    <VoskerPhotoCountElement />
    <VoskerHdPhotoCountElement />
    <FullHdVideoCountElement />
    <StreamingMinutesElement />
    <VoskerTransmissionPlanNameElement />
    { hideCameraName ? null : <CameraNameElement isNewUi /> }
  </>
)

export default VoskerTopBarItems
